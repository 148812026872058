import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import BlogImg from '../images/jeffrey.jpg';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import { FacebookProvider, Comments } from 'react-facebook';
import FeaturedPosts from '../components/featuredPosts';
import CardsRowImg3 from '../images/cardsRowImg3.png';
import DavidImg from '../images/David.png';

class Jeffreyjoinssports extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  
  render(){
    return(
      <Layout>
        <div id="fb-root"></div>
          <div className={styles.landingSection}>
            <div className={styles.landingSectionSubDiv}>
              <text className={styles.blogHeader}>Attorney and Former Senate President Jeffrey V. Kessler Joins ProGame Sports</text>
              <div className={styles.subtitle}>
                <div className={styles.profileCol2}>
                  <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                  <text className={styles.profileTest}>By Madeline Nguyen</text>
                  <p>01 Dec, 2018</p>
                </div>
                <div className={styles.profileCol2} id={styles.profilecolId}>
                  <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                  <text className={styles.profileTest}>0 Comments</text>
                </div>
              </div>
              <div className={styles.blogimage}>
                <img className={styles.davidImg} src={BlogImg} alt="landingimg" />
              </div>
              <text className={styles.blogDesc}>
                <p>ProGame Sports is proud and excited to welcome attorney and long-standing public servant Jeff Kessler to the ProGame team. Jeff has recently joined ProGame as its legal counsel to provide his first rate legal acumen as ProGame prepares to launch its signature “Mountaineer” peer-to-peer gaming marketplace in West Virginia in 2019.</p>
                <p>Jeff served as a long-standing member of the West Virginia Senate, representing the 2nd district from 1997 to 2017. During his tenure, Jeff served as President of the Senate/Lieutenant Governor, Chairman of the Judiciary Committee and Senate Minority Leader, where he was highly praised on both sides of the aisle for his keen legal insight and extensive knowledge of legislative procedure and history. Privately, Jeff is a partner at the law firm Berry, Kessler, Crutchfield, Taylor & Gordon, located in Moundsville, West Virginia.</p>
                <p>“We are thrilled that Jeff has signed on to ProGame Sports as we put the final touches on the Mountaineer marketplace.  Jeff’s regulatory knowledge, contacts and legal skillset are a perfect fit for ProGame and offer us the best opportunity to successfully establish an exciting and enjoyable vehicle for peer-to-peer wagering on traditional and eSports,” says ProGame co-owner David A. Ealy.</p>
                <p>Jeff Kessler stated: “I am extremely excited to become part of a new West Virginia- based company that is on the cutting edge of legalized sports wagering and eSports opportunities here at home.  ProGame intends to show the world that West Virginians can compete on a national and international level and has already garnered the attention of Byteball, an internationally recognized leader in sports betting and blockchain technology.”</p>
                <p>Jeff’s initial focus will be on attaining a license to operate in WV to allow players to enjoy the benefits of the peer-to-peer gaming marketplace statewide, but also to launch new products and lead the company into other States as they come online to legalized gambling.</p>
                <p></p>
              </text>
              <div className={styles.oldPostsLink}>
              <a className={styles.servicelinks} href="/propme-signs-exclusive-deal"><text>{'<'}Older Post</text></a>
            <a className={styles.servicelinks} href="/fortnite-fever"><text>Newer Post{'>'}</text></a>
              </div>
              <div className={styles.BlogsocialLinksDiv}>
                <img className={styles.social} src={Facebook} alt="landingimg" />
                <img className={styles.social} src={Twitter} alt="landingimg" />
                <img className={styles.social} src={Linkedin} alt="landingimg" />
                <img className={styles.social} src={MailBlue} alt="landingimg" />
              </div>
              <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fblas%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
              <div className={styles.featuredNewsDiv}>
                <text>Featured News</text>
              </div>
              </div>
            </div>
          <div>
          <FeaturedPosts/>
        </div>
      </Layout>
    )
  }
}

export default Jeffreyjoinssports